.icon-with-background {
  @apply w-12 h-12 rounded-full;
  background-color: var(--color-primary-with-opacity);
  color: var(--color-primary);
}

.client-colour-icon { 
  color: var(--color-primary);
}

div[data-state="open"] .icon-inactive {
display: none;
}

div[data-state="closed"] .icon-active{
  display: none;
}