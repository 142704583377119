.touch-nav-item {
  @apply flex items-center text-center text-gray-600;
}

.touch-nav-item:active,
.touch-nav-item:hover {
  @apply text-gray-800;
}

.touch-nav-item a,
.touch-nav-item button {
  @apply px-5 py-3;
}

.touch-nav-item span {
  @apply text-xs block;
}

.touch-nav-item.with-badge {
  position: relative;
}

.touch-nav-item .badge-label {
  position: absolute;
  right: 5px;
  top: 5px;
}