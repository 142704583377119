/* Styles for the competency "More info" popup in journeys and collab docs */
.competency-info-popup ul {
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 0.5rem;
}

/* Styles for the generic "More info" popup in journeys and collab docs */
.generic-more-info-popup ul {
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 0.5rem;
}

/* Styles for the competency subtext in journeys and collab docs */
.behaviour-subtext ul {
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 0.5rem;
}

/* Month and year dropdowns in the datepicker */
.datepicker-container select {
  @apply text-sm py-1;
}

.react-time-picker__inputGroup__input{
  background-color: none;
}

input[type="number"].react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour, input[type="number"].react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute {
  background: none !important;
  outline:0;
}

input[type="number"].react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour:focus{
  border: none;
  outline:0;
  outline-color: transparent;
  outline:none !important;
}

div.react-time-picker__wrapper{
  border:none;
  border-radius: 0.375rem;
}

/* Single Checkboxes */
.CheckboxContainer {
  @apply pl-1;
}

.CheckboxRoot {
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  @apply my-1 shadow-sm;
}
.CheckboxRoot:hover {
  background-color: #f0f0f0;
}
.CheckboxRoot:focus {
  box-shadow: 0 0 0 2px #333;
}

.CheckboxIndicator {
  color: var(--color-accent);
}