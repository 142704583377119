#journey-widget .journey-custom-content,
#journey-widget .journey-custom-content h1,
#journey-widget .journey-custom-content h2,
#journey-widget .journey-custom-content h3 {
  @apply text-white mt-0;
}

#journey-widget .journey-custom-content h1 {
  @apply mb-4 text-2xl font-semibold;
}

#journey-widget .journey-custom-content h2,
#journey-updates-container h2,
#pre-journey-screen h2 {
  @apply mb-3 text-2xl font-semibold;
}

#journey-widget .journey-custom-content h3 {
  @apply mb-2 text-xl font-semibold;
  font-family: "Poppins", sans-serif;
}

#journey-widget .journey-custom-content p {
  @apply mb-2 text-white/90 font-normal;
}

#journey-widget .journey-custom-content.welcome-message p,
#journey-widget .journey-custom-content.welcome-message span {
  @apply mb-2 text-white/90 font-medium text-lg tracking-[.015em];
}
#focused-journey-bg {
  @apply hidden;
}

body.focus-journey #focused-journey-bg {
  @apply lg:block lg:fixed lg:top-0 lg:left-0 lg:right-0 lg:bottom-0 lg:w-full lg:h-screen lg:z-20 lg:overflow-hidden lg:bg-gray-700 lg:opacity-95;
}

body.focus-journey #focused-journey-wrapper {
  @apply lg:fixed lg:top-0 lg:left-0 lg:right-0 lg:bottom-0 lg:w-full lg:h-screen lg:z-50 lg:overflow-hidden lg:flex lg:flex-col lg:items-center lg:justify-center;
}

#journey-widget,
#journey-widget #journey-form-scroller {
  @apply rounded-xl drop-shadow-xl;
}

#journey-widget.with-header #journey-form-scroller {
  @apply rounded-b-xl rounded-t-none;
}

body.focus-journey #journey-widget {
  @apply lg:w-4/5 xl:w-3/5 lg:drop-shadow-none lg:max-h-[80%] lg:border-2 lg:border-white;
}

body.focus-journey #journey-form-scroller {
  @apply lg:overflow-y-auto lg:max-h-[100%];
}

body.focus-journey #journey-widget.with-header #journey-form-scroller {
  @apply lg:max-h-[calc(100%-36px)] lg:rounded-lg; /* 36px is the height of the header/title bar */
}

#journey-widget-inner {
  @apply mb-4 border-solid pt-4 pb-6 px-4 md:px-16 md:pt-5 md:pb-8 min-h-72 drop-shadow-[0_4px_20px_rgba(33,71,57,0.25)];
}

body.focus-journey #journey-widget-inner {
  @apply lg:mb-0 lg:drop-shadow-none;
}

#defocus-journey-btn {
  @apply hidden;
}

body.focus-journey #defocus-journey-btn {
  @apply lg:inline-block fixed top-5 right-5;
}
