body {
  overflow-y: hidden;
}

#app-body {
  flex: 1 1 auto;
  overflow-y: auto;
}

#left-nav a.main-nav-link.active-nav-link,
#left-nav a.main-nav-link.inactive-nav-link {
  @apply block flex-nowrap text-sm;
}

#left-nav a.main-nav-link.inactive-nav-link:hover,
.left-nav-item-subnav:not(.active-subnav):hover {
  @apply bg-gray-200 rounded-md;
}

.main-nav-link.active-nav-link {
  @apply bg-gray-200 font-semibold rounded-md tracking-wide;
  color: var(--color-primary);
}

.main-nav-link.inactive-nav-link {
  @apply font-medium text-gray-400 tracking-wide;
}

.main-nav-link.inactive-nav-link .nav-icon-active {
  display: none;
}

.main-nav-link.active-nav-link .nav-icon-inactive {
  display: none;
}

.left-nav-item-subnav,
.left-nav-item-without-subnav {
  @apply py-3 px-4 mt-2 mx-4;
}

.left-nav-item-without-subnav:hover {
  @apply bg-gray-200 rounded-md;
}

.left-nav-item-subnav > a {
  @apply block text-sm;
}

.left-nav-item-subnav.active-subnav {
  @apply bg-gray-200 rounded-md;
}

.left-subnav {
  @apply pl-8 text-gray-600 text-sm;
}

.left-subnav > a {
  @apply block my-1;
}

#nav-powered-by {
  background-color: var(--color-primary);
  @apply block absolute bottom-0 left-0 right-0 m-auto select-none text-white py-3 text-sm;
}

.nav-with-sub.active-nav-link {
  @apply mb-0;
}

.max-h-none {
  max-height: none;
}

#walkthrough-button {
  background-color: var(--color-primary);
  opacity: 0.5;
  @apply absolute bottom-24 left-0 right-0 text-center text-sm p-2 text-white;
}

@media print {
  .print-avoid-break {
    break-inside: avoid;
  }

  .print-start-new-page {
    break-before: page;
  }

  .print\:max-h-none {
    max-height: none !important;
  }

  body,
  #app-body {
    overflow-y: visible !important;
  }
}
