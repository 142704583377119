.pagination-list-item {
  display: inline-block;
  padding: 5px;
  border: 1px solid lightgray;
  border-right: none;
  padding-left: 10px;
  padding-right: 10px;
}

.pagination-active-list-item {
  background-color: var(--color-primary);
  color: white;
}

.pagination-first-item {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination-last-item {
  border-right: 1px solid lightgray;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pagination-list-item.disabled a {
  @apply cursor-not-allowed text-gray-400;
}
