/* Defaults, e.g. for the spinner before the context loads and populates colour variables for the user */
/* Variables */
:root {
  --color-primary: #281f51;
  --color-accent: #281f51;
  --color-accent-rgb: 40, 31, 81;
  --color-primary-with-opacity: rgba(33, 71, 57, 0.2);
}

/* Using colour variables */
.accent-bg,
.bg-accent {
  background-color: var(--color-accent);
}

.accent-bg\/50,
.bg-accent\/50 {
  background-color: rgba(var(--color-accent-rgb), 0.5);
}

.border-accent {
  border-color: var(--color-accent);
}

.primary-bg {
  background-color: var(--color-primary);
}

.primary-bg-with-opacity {
  background-color: var(--color-primary-with-opacity);
}
