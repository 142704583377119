/*Animation for illustrations which include a plus icon in a circle*/
#group.loading {
    transform-box: fill-box;
    animation: rotate 2s linear infinite;
    transform-origin: center center;
}
#group.loading #addCircle {
    translate: 1s;
    animation: animate-stroke 1.5s ease-in-out infinite;
    stroke-width: 3;
}


@keyframes rotate {
    100% {
        transform: rotate(360deg);
       
    }
}


@keyframes animate-stroke {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}