.react-time-picker__inputGroup input {
  @apply leading-4 focus:outline-0 focus:ring-0 focus:ring-offset-0;
  height: auto;
}

.react-datepicker__header { 
  background-color: #fff;
  border: none;
}

.datepicker-container select{
  border-color: #d1d5db;
  border-radius: 0.25rem;
}

.react-datepicker__time-container{
  width: 100px;
}
.react-datepicker__month-container{
padding: 8px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button){
  right: 100px;
}
.react-datepicker__day-names{
  color:#4b5563;
  font-weight: 600;
}

.react-datepicker__day--selected , .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 0.3rem;
}

.react-datepicker__day:hover, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover, .react-datepicker__day--selected:hover, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover{
  background-color: var(--color-primary);
  opacity: 0.8;
  border-radius: 0.3rem;
  color: #fff;
}