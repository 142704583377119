/* width */
::-webkit-scrollbar {
  @apply rounded-full w-3;
}

/* Track */
::-webkit-scrollbar-track {
  @apply w-2 bg-transparent;
  background-color: rgba(0,0,0,0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply rounded-full border-solid border shadow-lg border-gray-200;
  background-color: #CCC;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply rounded-full w-2;
  background-color: #777;
}