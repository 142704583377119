.loader-icon {
  color: var(--color-primary);
}

.dotted-loader-outers {
  --fa-animation-duration: 1.4s;
}

.dotted-loader-inner {
  --fa-animation-duration: 1.25s;
}