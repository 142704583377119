.success-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 5;
  stroke-miterlimit: 10;
  stroke: rgba(255, 255, 255, 0.4);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.success-wrapper.success-wrapper-dark .checkmark__circle {
  stroke: rgba(33, 71, 57, 0.4);
}

.checkmark {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  display: block;
  stroke-width: 3;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #fff;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.success-wrapper.success-wrapper-dark .checkmark {
  stroke: #281F51;
  box-shadow: inset 0px 0px 0px #281F51;
}

.checkmark__check {
  transform-origin: 50% 50%;
  border-radius: 100%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
  }
}
