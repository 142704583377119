.esf-question-container.invalid input[type="text"],
.esf-question-container.invalid textarea,
.esf-question-container.invalid select {
  @apply border-solid border-[1px] border-red-400;
}

.esf-question-more-info-panel {
  @apply px-2 py-1 text-gray-700 text-sm border-l-2 border-sky-200 bg-sky-50 my-1 max-h-32 overflow-y-auto;
}

.esf-question-more-info-panel-readonly {
  @apply px-2 py-1 text-gray-700 text-sm border-l-2 border-gray-200 bg-gray-100 my-1 max-h-32 overflow-y-auto;
}

.esf-question-more-info-panel a {
  @apply underline hover:text-gray-800;
}
