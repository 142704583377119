.advanced-task-popup .esf-question-container {
  @apply mb-2;
}

.advanced-task-popup .task-field.invalid input[type="text"],
.advanced-task-popup .task-field.invalid textarea,
.advanced-task-popup .task-field.invalid select {
  @apply border-solid border-[1px] border-red-400;
}

.task-type-banner-content {
  @apply text-gray-900;
}

.task-type-banner-content a {
  @apply underline;
}

.task-type-banner-content > ul {
  list-style: disc;
  padding-left: 30px;
}

.to-do-edit:hover .icon-edit,
.to-do-edit .icon-edit-solid {
    display: none;
}
.to-do-edit:hover .icon-edit-solid {
    display: inline;
}


.to-do-list-item:hover .icon-unhover,
.to-do-list-item .icon-hover {
    display: none;
}
.to-do-list-item:hover .icon-hover {
    display: inline;
}