#analytics-widgets-container {
  @apply px-2;
}

#analytics-widgets-container > div {
  @apply py-4;
}

#analytics-widgets-container > div:not(:last-child) {
  @apply border-b-gray-300 border-b-[1px];
}

table.datatable {
  @apply w-full border-separate;
  border-spacing: 0;
}
table.datatable tr th,
table.datatable tr td {
  @apply px-2 py-1;

  border-bottom: 1px solid #d6d6d6;
}
table.datatable tr th:first-child,
table.datatable tr td:first-child {

}
table.datatable tr th {

}
table.datatable tr th {
  @apply bg-slate-100;
}

table.datatable tr:nth-child(2n) td {
  @apply bg-slate-50;
}

/* top-left border-radius */
table.datatable tr:first-child th:first-child {
  border-top-left-radius: 3px;
}

/* top-right border-radius */
table.datatable tr:first-child th:last-child {
  border-top-right-radius: 3px;
}

/* bottom-left border-radius */
table.datatable tr:last-child td:first-child {
  border-bottom-left-radius: 3px;
}

/* bottom-right border-radius */
table.datatable tr:last-child td:last-child {
  border-bottom-right-radius: 3px;
}

.analytics-widget-help-container p,
.analytics-widget-help-container ul {
  @apply mb-2;
}

.analytics-widget-help-container ul {
  @apply list-disc ml-6;
}

.axis-box {
  position: relative;
  border-color: #9b9b9b;
}

.axis-box:before {
  /* y-axis arrow */
  position: absolute;
  content: "";
  top: 0;
  left: -10px;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #9b9b9b;
  border-left: 10px solid transparent;
}

.axis-box:after {
  /* x-axis arrow */
  position: absolute;
  content: "";
  bottom: -10px;
  right: 0;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #9b9b9b;
}

.donut-container {
  @apply w-full text-xl my-0 mx-auto;
  animation: donutfade 1s;
  overflow: visible;
}

@keyframes donutfade {
  /* this applies to the whole svg item wrapper */
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.donut-segment {
  transform-origin: center;
}

.donut-percent {
  animation: donutfadelong 1s;
}

.donut-label {
  font-size: 0.28em;
  font-weight: 700;
  line-height: 1;
  fill: #000;
  transform: translateY(0.25em);
}

.donut-percent {
  font-size: 0.5em;
  line-height: 1;
  transform: translateY(0.5em);
  font-weight: bold;
}

.donut-data {
  font-size: 0.12em;
  line-height: 1;
  transform: translateY(0.5em);
  text-align: center;
  text-anchor: middle;
  color: #666;
  fill: #666;
  animation: donutfadelong 1s;
}

.donut-container:hover .donut-text,
.donut-container:hover .donut-segment {
  @apply transition ease-in-out delay-150 brightness-90;
}

.donut-container:hover .donut-text,
.donut-container:hover .donut-segment,
.donut-container:hover .donut-ring,
.donut-container:hover .donut-hole,
.donut-container:hover .donut-data {
  @apply transition ease-in-out delay-150 scale-105 origin-center;
}

@keyframes donutfadelong {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
