@tailwind base;
@tailwind components;
@tailwind utilities;

/* Common components */
@import "./css/variables.css";
@import "./css/fonts/fonts.css";
@import "./css/common/buttons.css";
@import "./css/common/headings.css";
@import "./css/common/loaders.css";
@import "./css/common/modals.css";
@import "./css/common/number-input.css";
@import "./css/common/colour-picker.css";
@import "./css/common/text.css";
@import "./css/common/widgets.css";
@import "./css/common/icons.css";
@import "./css/common/scrollbar.css";
@import "./css/common/switch.css";
@import "./css/common/date-picker.css";
@import "./css/common/list.css";

/* Animations */
@import "./css/animations/adding.css";

/* Features */
@import "./css/features/advanced-tasks.css";
@import "./css/features/analytics.css";
@import "./css/features/collab-docs.css";
@import "./css/features/journeys.css";
@import "./css/features/nav.touch.css";
@import "./css/features/layout.css";
@import "./css/features/success.tick";
@import "./css/features/forms.css";
@import "./css/features/pagination.css";
@import "./css/features/simple-forms.css";
@import "./css/features/plugins.css";
