#collab-doc-container .readonly-question,
#collab-doc-container .readonly-question h1,
#collab-doc-container .readonly-question h2,
#collab-doc-container .readonly-question h3 {
  @apply mt-0;
}

#collab-doc-container .readonly-question h1 {
  @apply mb-4 text-2xl font-semibold;
}

#collab-doc-container .readonly-question h2 {
  @apply mb-3 text-xl font-semibold;
}

#collab-doc-container .readonly-question h3 {
  @apply mb-2 text-lg font-semibold;
}

#collab-doc-container .hide-in-collab-doc {
  @apply hidden;
}
